const ENV = {
  dev: {
    // apiUrl: 'http://localhost:44381',
    apiUrl: 'https://admin.wiply.com',
    oAuthConfig: {
      issuer: 'https://admin.wiply.com',
      clientId: 'Wiplify_React',
      clientSecret: 'ehbn8765',
      scope: 'offline_access Wiplify',
    },
    localization: {
      defaultResourceName: 'Wiplify',
    },
  },
  prod: {
    apiUrl: 'https://admin.wiply.com',
    oAuthConfig: {
      issuer: 'https://admin.wiply.com',
      clientId: 'Wiplify_React',
      clientSecret: 'ehbn8765',
      scope: 'offline_access Wiplify',
    },
    localization: {
      defaultResourceName: 'Wiplify',
    },
  },
};

export const getEnvVars = () => {
  // eslint-disable-next-line no-undef
  return ENV.prod
  // return __DEV__ ? ENV.dev : ENV.prod;
};
