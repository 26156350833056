import mock from "../mock";

const data = {
  // blog list
  blogList: [
    {
      img: require("@src/assets/images/slider/006.jpg").default,
      title: "5 Ways to Create Conversion Rates of 5%-8%: Gamification Tactics",
      id: 15,
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      blogPosted: "Aug 28, 2022",
      tags: ["Conversion", "Sales"],
      excerpt: "Learn three gamification tactics that have been proven to work in increasing conversion rates for all types of businesses.",
      comment: 15,
    },
    {
      img: require("@src/assets/images/slider/001.jpg").default,
      title: "How to Gamify Your E-commerce Website on a Budget ",
      id: 14,
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      blogPosted: "Aug 15, 2022",
      tags: ["Conversion", "Sales"],
      excerpt:
        " Wiply.com is a great solution for businesses looking to add gamification to their website on a budget. ",
      comment: 15,
    },
    {
      img: require("@src/assets/images/slider/003.jpg").default,
      title: "How Gamification Can Improve the Conversion Rate on Your Website",
      id: 12,
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      blogPosted: "Jul 07, 2022",
      tags: ["Conversion", "Sales"],
      excerpt: "Want to know how to get leads for your business? ",
      comment: 15,
    },
    {
      img: require("@src/assets/images/slider/009.jpg").default,
      title:
        "Why wiply.com is the Future of Advertising: Add Innovation for Maximum Results",
      id: 13,
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      blogPosted: "Jul 03, 2022",
      tags: ["Conversion", "Sales"],
      excerpt: "Want to know how to get leads for your business? ",
      comment: 15,
    },
    {
      img: require("@src/assets/images/slider/011.jpg").default,
      title: "How to Get Leads for Your Business: A Guide Using Wiply.com",
      id: 11,
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      blogPosted: "Jun 27, 2022",
      tags: ["Conversion", "Sales"],
      excerpt: "Want to know how to get leads for your business? ",
      comment: 15,
    },
    {
      img: require("@src/assets/images/slider/use-gamification-to-improve-ux.png")
        .default,
      title: "How to use gamification to improve UX",
      id: 10,
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      blogPosted: "Jun 15, 2022",
      tags: ["Gaming", "Sales"],
      excerpt:
        "Gamification can be a great way to improve UX, by adding games and making the experience more fun and engaging.",
      comment: 5,
    },
    {
      img: require("@src/assets/images/slider/strategy-tip-for-your-business.png")
        .default,
      title: "Gamification Strategy Tips for Your Business",
      id: 9,
      avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
        .default,
      userFullName: "Max Hunt",
      blogPosted: "Jun 12, 2022",
      tags: ["E-commerce", "Gaming"],
      excerpt:
        "Companies are increasingly looking to gamification as a way to engage and motivate employees, customers, and partners.",
      comment: 24,
    },
    {
      img: require("@src/assets/images/slider/conversion-rate-optimazation.png")
        .default,
      title: "Conversion rate optimization in 7 practical steps",
      id: 8,
      avatar: require("@src/assets/images/portrait/small/avatar-s-11.jpg")
        .default,
      userFullName: "Helena Hunt",
      blogPosted: "Apr 14, 2022",
      tags: ["Conversion", "SEO"],
      excerpt:
        "In this article, we will discuss 7 (and another bonus tip at the end!) steps that will help increase conversion rate.",
      comment: 15,
    },
    {
      img: require("@src/assets/images/slider/increase-user-engagement-in-your-website.png")
        .default,
      title: "Increasing user engagement in you website",
      id: 1,
      avatar: require("@src/assets/images/portrait/small/avatar-s-7.jpg")
        .default,
      userFullName: "Ghani Pradita",
      blogPosted: "Mar 07 2022",
      tags: ["Gaming", "SEO"],
      excerpt:
        "User engagement is one of the most important aspects of a website. Without users engaging with your content, your website will not be successful. ",
      comment: 76,
    },
    {
      img: require("@src/assets/images/slider/mobile-commerce-effect-sales.png")
        .default,
      title: "How does mobile commerce affect sales and desktop website",
      id: 2,
      avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
        .default,
      userFullName: "Jorge Griffin",
      blogPosted: "Mar 02 2022",
      tags: ["Gaming", "Sales"],
      excerpt:
        "As mobile commerce continues to grow in popularity, it's having a larger and larger impact on sales for desktop ecommerce websites. ",
      comment: 2100,
    },
    {
      img: require("@src/assets/images/slider/004.jpg").default,
      title: "Benefits of gamifying your online shop",
      id: 3,
      avatar: require("@src/assets/images/portrait/small/avatar-s-3.jpg")
        .default,
      userFullName: "Claudia Neal",
      blogPosted: "Feb 22 2022",
      tags: ["Gaming", "Sales"],
      excerpt:
        'The phrase "gamification" may be a bit of a buzzword in online marketing and e-commerce but its still an important tool in any website owners arsenal.',
      comment: 243,
    },
    {
      img: require("@src/assets/images/slider/optimize-your-website-content-for-maximum-conversion.png")
        .default,
      title: "How to Optimize Your Website Content for Maximum Conversions",
      id: 4,
      avatar: require("@src/assets/images/portrait/small/avatar-s-14.jpg")
        .default,
      userFullName: "Fred Boone",
      blogPosted: "Feb 17 2022",
      tags: ["Conversion", "Sales"],
      excerpt:
        "When it comes to creating website content, many business owners feel overwhelmed. How can I make sure my website is optimized for conversions? ",
      comment: 10,
    },
    {
      img: require("@src/assets/images/slider/increase-ctr.png").default,
      title: "CTR as a standard for evaluating success in e-commerce ",
      id: 5,
      avatar: require("@src/assets/images/portrait/small/avatar-s-13.jpg")
        .default,
      userFullName: "Billy French",
      blogPosted: "Feb 06 2022",
      tags: ["E-commerce", "SEO"],
      excerpt:
        "CTR has become a standard for evaluating the success of online marketing campaigns. What exactly is it? How does it work? ",
      comment: 319,
    },
    {
      img: require("@src/assets/images/slider/010.jpg").default,
      title: "How to Increase Click-Through Rate: A Comprehensive Guide",
      id: 6,
      avatar: require("@src/assets/images/portrait/small/avatar-s-13.jpg")
        .default,
      userFullName: "Helena Hunt",
      blogPosted: "Feb 03 2022",
      tags: ["Conversion", "SEO"],
      excerpt:
        "CTR, click-through rate, is a metric that tells you how often people click on your links as opposed to just seeing them. ",
      comment: 1500,
    },
    // {
    //   img: require('@src/assets/images/slider/001.jpg').default,
    //   title: 'Conversion rate optimization in 9 practical steps.',
    //   id: 7,
    //   avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
    //   userFullName: 'Helena Hunt',
    //   blogPosted: 'Jan 01, 2022',
    //   tags: ['Fashion', 'Video'],
    //   excerpt: 'Many businesses spend time trying to increase traffic to their website, and fail to improve the conversion rate once they get there. This is a huge mistake. In this article, we will discuss 9 steps that will help increase and optimize your conversion rates and generate more revenue in your eCommerce!',
    //   comment: 1500
    // }
  ],

  // sidebar
  blogSidebar: {
    recentPosts: [
      {
        img: require("@src/assets/images/slider/002.jpg").default,
        title: "Increasing user engagement in you website",
        id: 1,
        createdTime: "Mar 07 2022",
      },
      {
        img: require("@src/assets/images/slider/006.jpg").default,
        title: "How does mobile commerce affect sales and desktop website",
        id: 2,
        createdTime: "Mar 02 2022",
      },
      {
        img: require("@src/assets/images/slider/004.jpg").default,
        title: "Benefits of gamifying your online shop",
        id: 3,
        createdTime: "Feb 22 2022",
      },
      {
        img: require("@src/assets/images/slider/003.jpg").default,
        title: "How to Optimize Your Website Content for Maximum Conversions",
        id: 4,
        createdTime: "Feb 17 2022",
      },
    ],
    categories: [
      { category: "SEO", icon: "Watch" },
      { category: "Conversion", icon: "ShoppingCart" },
      { category: "Gaming", icon: "Command" },
      { category: "E-commerce", icon: "Quote" },
      { category: "Sales", icon: "Video" },
    ],
  },

  // detail
  blogDetailIncreasingUserEngagement: {
    blog: {
      img: require("@src/assets/images/banner/increase-user-engagement-in-your-website.jpg")
        .default,
      title: "Increasing user engagement in you website",
      avatar: require("@src/assets/images/portrait/small/avatar-s-7.jpg")
        .default,
      userFullName: "Ghani Pradita",
      createdTime: "Mar 07, 2022",
      tags: ["Gaming", "SEO"],
      metaDescription:
        "User engagement is one of the most important aspects of a website. learn how to increse it in a few easy steps >>",
      metaTitle: "Increasing user engagement in you website",
      content: `
       <p>
User engagement is one of the most important aspects of a website. Without users engaging with your content, your website will not be successful. There are many techniques you can use to increase user engagement on your website. In this article, we will discuss some of the most effective ways to engage your users.

As more websites go live on the web it's getting harder to build a strong & engaging website that will put you above your competitors and will make your user want to come back to you. With that been said, more and more website owners are looking for ways to make their websites better.

If you're not quite sure how to get it done - we are here for you! Together we will learn about the best ways of making a better engaging website so you can get more of your goals achieved.
</p>
<h2>Keeping your content updated and relevant </h2>
<p>
This means regularly adding new content to your website, whether it be articles, blog posts, images, or videos. stale content can quickly bore users, so keep them engaged by providing new and interesting material. That means you need to put on a list a couple of hours on a weekly basis to create content relevant for your users - a good way to get it done is to plan ahead of time and create a list of article headings and pictures so every week you're gonna have a subject to write about.

</p>
<h3> Interactive website is the new game changer</h3>
<p>
Allow users to interact with your content by providing quizzes, polls, or surveys. This will allow users to engage with your content on a deeper level and help them learn more about your website.
And if you want to take it to the next level - gamify your website! Research shows that websites with gamification and rewards have a better engagement by users as long as more transactions. The best thing about it is that here in Wiply that's what we do and for free! absolutely free - you can modify and customize any game from our platform and easily upload it to any website platform you want.
</p>

<h3>UI can make the difference </h3>
<p>
You can also engage users by making your website visually appealing. This means using attractive visuals and graphics to capture the user’s attention. You can do this by using bright colors, interesting shapes, and attractive images.
If you are low on budget and don’t have money or the resources to hire a graphic designer but you still want to get stunning graphics done - we have a solution for you. By now Canva has taken control of the graphic design world for beginners, the platform allows you to create stunning graphics with just basic knowledge!
</p>

<h3>Personalized the user experience</h3>
<p>
You can engage users by providing a personalized experience. This means tailoring your content to fit the user’s interests and needs. You can do this by using cookies to track the user’s activity on your website or by asking the user to provide their preferences. Basically the set of mind is to make the user feel like he is a part of the website and not just a visitor, that will make him want to stay more and build his confidence in the website.

That's it, now you know how to increase user engagement in your website in just a few easy steps! If you want to keep up to date with our newsletter make sure you sign up, it will help you navigate the hard world of being a website owner with the best tips and tricks.
</p>

       `,
      comments: 19100,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "Mar 08, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailMobileCommerce: {
    blog: {
      img: require("@src/assets/images/banner/mobile-commerce-effect-sales.jpg")
        .default,
      title: "How does mobile commerce affect sales and desktop website",
      avatar: require("@src/assets/images/portrait/small/avatar-s-7.jpg")
        .default,
      userFullName: "Ghani Pradita",
      createdTime: "Mar 02, 2022",
      metaDescription: `As mobile commerce continues to grow in popularity, it's having a larger and larger impact on sales for desktop ecommerce websites.`,
      metaTitle: "How does mobile commerce affect sales and desktop website",
      tags: ["Gaming", "Sales"],
      content: `
      <p>
      As mobile commerce continues to grow in popularity, it's having a larger and larger impact on sales for desktop ecommerce websites. Here are just a few of the ways that mobile commerce is changing the ecommerce landscape:
      </p>
      <h3>Shoppers are increasingly using their phones to make purchases  </h3>
      <p>
      Whether they're buying items online or in-store. In fact, a recent study found that mobile commerce accounted for 30% of all online retail sales in 2017. That number is only going to increase as more and more people become comfortable making purchases with their phones.
      </p>
      <h3> Mobile shopping apps are becoming increasingly sophisticated </h3>
      <p>
      Offering a range of features that make it easy for shoppers to find what they're looking for and complete transactions quickly and easily. For instance, many shopping apps allow you to save your payment information so that you can buy items faster and without having to enter your information each time.
      </p>

      <h3>Mobile commerce is particularly well-suited to impulse buys </h3>
      <p>
      Because we're typically using our phones when we're out and about, it's easy to spot a deal or a product that we're interested in and make a purchase without having to wait. In fact, a study by Criteo found that mobile shoppers are 3x more likely to make an impulse purchase than desktop shoppers.
      </p>
      <h3>Optimize desktop websites for mobile </h3>

      <p>
      Desktop ecommerce websites need to make sure that their sites are optimized for mobile shoppers. If your site isn't mobile-friendly, you're likely to lose out on sales to competitors who are. In fact, a study by Google found that 61% of mobile users are unlikely to return to a website that's difficult to use on their phone.
      As you can see, mobile commerce is having a major impact on the ecommerce landscape. If you're not already optimized for mobile shoppers, it's time to start making changes. By doing so, you'll be better positioned to take advantage of the growth in mobile commerce and increase your sales.
      </p>

      <h2>Here are some tips for increasing income on your mobile commerce</h2>
      <p>
      In today's digital world, it's more important than ever to have a successful mobile ecommerce website. Not only is this the future of retail, but it's also where most consumers are doing their shopping. So how can you make sure your mobile ecommerce website is generating the income you want? Here are all the tips:
      </p>

      <h3>Use effective imagery </h3>
      <p>
      One of the best ways to engage and entice customers is with great imagery. Make sure your images are high quality, and that they showcase your products in the best light possible.
      </p>

      <h3>Optimize your website for search engines</h3>
      <p>
      This is another important factor that can help you grow your income. Make sure you're using the right keywords and phrases, and that your website is properly optimized for search engines.
      </p>

      <h3>Offer great customer service </h3>
      <p>

      Nothing is more frustrating than dealing with a company that doesn't offer great customer service. Make sure your team is knowledgeable and friendly, and that they're always available to help customers with any questions or concerns they may have.
      </p>

      <h3>Use social media to promote your website </h3>
      <p>
      Social media is a great way to reach out to more customers and promote your website. Make sure you're using all the social media platforms available to you, and that your content is engaging and relevant.
      </p>

      <h3>Offer promotions and discounts </h3>
      <p>
      One of the best ways to entice customers is to offer them promotions and discounts. Make sure your website has a section where customers can view all of your current offers, and be sure to promote them on social media and in your marketing materials.
      </p>

      <h3>Keep your website up-to-date  </h3>
      <p>
      It's important to keep your website up-to-date with the latest trends and technologies. This will help keep your customers engaged, and it will show them that you're a cutting-edge company.
      </p>

      <h3>Track your website's analytics  </h3>
      <p>
      Finally, be sure to track your website's analytics so you can see how well it's performing. This will help you make adjustments and improvements as needed.
      By following these tips, you can help make sure your mobile ecommerce website is generating the income you want. So get started today and see the results for yourself!
      </p>
      `,
      comments: 19100,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "Mar 21, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailBenefitsOfGamifying: {
    blog: {
      img: require("@src/assets/images/banner/3.jpg").default,
      title: "Benefits of gamifying your online shop",
      avatar: require("@src/assets/images/portrait/small/avatar-s-7.jpg")
        .default,
      userFullName: "Ghani Pradita",
      createdTime: "Feb 22, 2022",
      tags: ["Gaming", "Sales"],
      metaDescription:
        "Learn all the pros & cons of gamification for e-commerce websites in our article >>",
      metaTitle: "Benefits of gamifying your online shop",
      content: `
      <p>
      The phrase "gamification" may be a bit of a buzzword in online marketing and e-commerce but it's still an important tool in any website owner's arsenal.
      People interact with games in fundamentally different ways than they interact with websites, traditional advertising or even social media. This kind of user behavior can be incredibly valuable for retail companies that want to increase their sales or target specific demographics.
      The most important thing about gamifying an online shop is that it will usually pay for itself in sheer volume of product sold. Users are often more willing to buy something when they have invested time in getting to know a game world, regardless of whether the game-world has any direct connection with the products being sold.
      The first step in gamifying your online shop is to choose the game that will be least off-putting to people shopping for products or services. The old "farmville" style of casual games are good for this purpose but so are highly stylized one-on-one fighter games, action RPGs and even shoot-'em-ups.
      In all cases, the idea is to have a world that encourages your customers to explore and spend more time on your site. Choose a game that will appeal as much as possible to the demographic you want to target with your products or services.

      </p>
      <h3>The main problems with gamification of online stores are:   </h3>
      <p>

      Lack of suitable games - not all games can be adapted to suit your needs. Lack of gamification experience - some web developers have never done gamification before so they cannot provide a quality service or product. Inadequate Results - some people overestimate the impact of gamification on consumer behavior and expect too much from it, thus their results are not as good as they could have been.

      </p>
      <h3>One known example of a website that did it right</h3>
      <p>
      In order to address these problems, Olymptrade came up with a solution that takes the headache out of gamifying an online shop and provides outstanding results as well.
      Olymptrade is a Binary Options Trading platform which has used gamification to make its platform look like a game. The first thing you are presented with on Olymptrade's site is a game world. The game rewards you for opening an account, logging in and trading (and not just once - every time you trade, the game checks if you've gained any new levels).
      This gamification turns mere e-commerce into entertainment that is actually fun to engage with. The result is that Olymptrade has more registered users than any other Binary Option Platform in the world.


      </p>

      <h2>The main benefits of gamifying your online shop are:</h2>
      <p>
      Improved conversion rates - people are often more open to buying something when they have invested time in getting to know another game world, regardless of whether the game-world has any direct connection with the products being sold. Increased time on site and return visits - every time you trade, the game checks if you've gained any new levels which means that users will naturally want to come back and see what they can achieve next. Higher quality leads (and therefore sales) as gamification increases user engagement.
      This is beneficial for both shop and customer, as it means a better user experience for the customer and increased sales for the shop.
      The most important thing to remember is that quality matters just as much as quantity when it comes to implementing gamification in an online store. That is where we come through - Wiply as a well known gamification platform for e-Commerce websites. With our easy and intuitive platform you'll be able to modify a custom game for your own website including adding your logo, special discounts and lots of more options!
      The best part is that it's all for FREE and it can be added to any website no matter on which platform it is built on. So have a go at our games and put yourself ahead of your competitors.
      </p>
      `,
      comments: 19100,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "Mar 01, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailOptimizeWebsiteContent: {
    blog: {
      img: require("@src/assets/images/slider/optimize-your-website-content-for-maximum-conversion.png")
        .default,
      title: "How to Optimize Your Website Content for Maximum Conversions",
      avatar: require("@src/assets/images/portrait/small/avatar-s-7.jpg")
        .default,
      userFullName: "Ghani Pradita",
      createdTime: "Feb 17, 2022",
      tags: ["Conversion", "Sales"],
      metaDescription:
        "When it comes to creating website content, many business owners feel overwhelmed. Learn how to creat content easily >>",
      metaTitle: "How to Optimize Your Website Content for Maximum Conversions",
      content: `
<p>
When it comes to creating website content, many business owners feel overwhelmed. What should I write about? How can I make sure my website is optimized for conversions? In this blog post, we will provide you with tips and advice on how to create content that will help you achieve your desired results. By following our simple guidelines, you can optimize your website content for maximum conversions and see a significant improvement in your bottom line!

The most fundamental criteria for creating a converting content is to know your audience, what they are looking for, where you might be able to meet them and how you might be able to help them. But many business and site owners are not sure where to start, so here are some ideas to help grow your website conversions through content optimization:

First, you need to know what kind of product or service they will need. If you are not sure about this yet or you are still in the planning stages of your business venture, that's okay. For now, just realize that it's better to do a little research and plan it out.

Once you have your product or service and business goals all planned and ready to go, you can start thinking about creating some engaging content on your website. One of the best ways to connect with customers is through informative content that they will actually enjoy reading and want to share with others as well. You can also keep it simple by offering a way for people to contact you or find out more about what you do.

After that, it's just a matter of getting the word out there about your new website and company! Getting traffic is key but without content and good conversion numbers, not many visitors will stick around. Try adding engaging images and videos as well as keeping your content fresh.

You might be surprised at how much traffic you get, if you're prepared and know what to expect. Create quality content that your audience will enjoy reading and share it with others, creating a cycle of traffic building up over time.
</p>
<h2>How to create quality content </h2>
<p>
The internet has made the distribution of news and information so widespread, there is often little reward for good quality content. These are our 10 goldem tips to help you improve your writing skills and get your content noticed.
</p>
<h3> Have a central focus </h3>
<p>
In the same way that a journey needs a destination, good writing requires focus. It is important to define what you are trying to achieve and concentrate on that. If it doesn't relate to the overall theme of your writing then take it out, unless it is so good it deserves a piece to itself. This focus will help your writing to remain coherent and allow you to mention related aspects that strengthen your main point.



This is something I have been told so often it has become a cliché, but it's because it's true. Even if you consider yourself a good writer, proofreading before publishing can help you to stand out from the crowd. It is also sometimes necessary for legal reasons, that's why lawyers do it.
</p>

<h3>Have an opinion</h3>
<p>
One of the biggest problems with writing online today is the sheer number of articles available. If something doesn't make your article unique in some way then there is little reason for people to read it. By having a clear opinion you create a hook that people can latch onto. Although it has become a cliche, "there's no such thing as bad publicity" still applies. As long as your target audience agrees with your point of view then they will be willing to engage with your writing and share it.
</p>

<h3>Write for real people</h3>
<p>
This is an extension of the last tip, write for your audience. By imagining you are writing to someone in particular it will keep your content relevant and engaging. You can even create profiles for people who fit into certain categories so that they feel like individuals when you talk to them. This personal touch helps to give people a greater connection with your writing and makes it more interesting.
</p>

<h3>Optimize your writing for search engines</h3>
<p>
Search engine optimization has become much less important than it used to be, because of social media sharing. That doesn't mean that you can forget about it entirely though. If you follow the tips in this post then your content will already be soundly optimized by default. If you can make the occasional subtle reference to common search terms then that will also help to boost your SEO and get your writing in front of more people.
</p>

<h3>Use images sparingly</h3>
<p>
Although sharing articles with images is a good idea, adding too many can reduce your website's speed and cause technical issues for some users. While it may seem like a good idea to include an image with every paragraph, it can make your content difficult to read and navigate. If you do feel like you need to add images then use them sparingly and keep them relevant to the text they accompany.
</p>

<h3>Keep your content concise</h3>
<p>
As we mentioned in tip 3, there is a lot of competition for people's attention these days. If you want your content to stand out then it needs to be concise and relevant. Make sure that everything you write is necessary and cut out the fluff. As a rule of thumb aim for less than 300 words per paragraph and no more than 2500 in total.
</p>

<h3>Get involved with social media</h3>
<p>

By sharing your content with people on Facebook, Twitter, Pinterest and other social networks you increase the exposure of your work. Social media is also a good place to meet potential clients who are likely to be interested in hiring you or buying products from your business. If someone likes what they read then there is a good chance that they will want more so you can direct them to your website or a sign up page.
</p>

<h3>Promote yourself online</h3>
<p>
Build a good online presence and promote it wherever you can. Make sure that all of your social media profiles are updated regularly with fresh, interesting content. If you want people to read what you write then they need to know where to find it so get your name out there.
</p>

<h3>Keep writing</h3>
<p>
When you are new to the world of online writing it can seem difficult but don't give up. Even if you write one or two articles a week, over time that will add up to thousands which is good for both your bank balance and your reputation. The more you write the better at it you will become so keep going and you will reap the rewards.

Hopefully this article will help you get a better conversion rate through your content and help you optimize your content creation. for more info, guids and articles visit our blog!
</p>
`,
      comments: 19100,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailCTR: {
    blog: {
      img: require("@src/assets/images/slider/increase-ctr.png").default,
      title: "CTR as a standard for evaluating success in e-commerce",
      avatar: require("@src/assets/images/portrait/small/avatar-s-7.jpg")
        .default,
      userFullName: "Ghani Pradita",
      createdTime: "Feb 06, 2022",
      tags: ["E-commerce", "SEO"],
      metaDescription:
        "CTR has become a standard for evaluating the success of online marketing campaigns. What exactly is CTR? Learn more >>",
      metaTitle: "CTR as a standard for evaluating success in e-commerce",
      content: `

      <p>
      CTR has become a standard for evaluating the success of online marketing campaigns. What exactly is it? How does it work? And what are some other metrics that you might want to track in your campaign alongside CTR? Find out everything you need to know about this useful metric!

      </p>
      <h4>In this article we will cover the following topics:   </h4>

      <ul>
      <li>CTR - definition</li>
      <li>The history of clickthrough rate metric</li>
      <li>clickthrough rate and Adwords relationship</li>
      <li>	How clickthrough rate is measure</li>
      <li>Using CTR as a benchmark</li>
      </ul>
      <h3>CTR - definition </h3>
      <p>
      CTR is a key metric that you can use to gauge how much traffic your website gets. clickthrough rate could also be written as clickthrough rate, which is defined as the percentage of users who view one web page and then click on a specific link. CTR is usually applied in the context of online marketing campaigns – if you have an ad campaign running on Google or Facebook, CTR is a good indicator of how much exposure your campaign has gotten. clickthrough rate can be used in combination with other metrics to determine the success of an online marketing campaign.

      </p>

      <h3>The history of clickthrough rate metric </h3>
      <p>
      How did we come up with this stat? The CTR was first mentioned in a 1999 report where the author used clickthrough rate as a measure for assessing his results from an Adwords campaign. Back also coined the term "click-through marketing" to describe this type of online advertising, and he was really into it: In 1999 Back stated that 'half of all internet users' were clicking on ads.
      Although CTR was not mentioned in the 2000s, it didn't stop marketers from using it; on the contrary, clickthrough rate became more and more popular the next decade. Especially companies owning their platform (like Facebook) started to make use of this metric. Also important: the Google Display Network (GDN).


      </p>

      <h3>Clickthrough rate and Adwords relationship  </h3>
      <p>
      Network is driven by PPC ads, so CTR was a very important factor when placing your ads. The CTR history in Google Adwords started with different types of Ads. In the beginning it was text only, but in 2006 Google introduced Image ads in their PPC campaigns. And this is when they also introduced the clickthrough rate metric for these campaigns.
      Image ads have a higher CTR than text ads, so it is logical to place them above your competitor's text ads for better results. In 2007 Google introduced Enhanced CPC (targeting + scheduling), this allows marketers to bid on CPM instead of CPC. Nowadays CPM has been replaced by the Smart bidding in Adwords.
      This bidding method is also affected by CTR, but only when the searcher uses mobile devices. Google has added many new features to Adwords over time; in late 2012 Google announced app extensions and callouts. The clickthrough rate of these ads can be really high (up to 30%) which makes them very interesting for marketers wanting more conversions.
      Recently Google has announced that the content network will be removed in Adwords, this network was very important for small businesses, since it allowed them to reach a larger audience.
      Nowadays CTRs vary between 1% and 7%, with an average of 3%. This means your ads should have at least 3 keywords above your competition in order to get good results. And that's the history of the clickthrough rate metric in Adwords.
      </p>

      <h3>How clickthrough rate is measure  </h3>
      <p>
      One of the best things about CTR is that it's not particularly hard to calculate. CTR is calculated by taking the number of people who click on your ad and dividing it by the amount of people who actually saw your ad (in other words, CTR = CTR ads / impressions). CTR is usually expressed as a percentage or decimal number and can be determined over specific periods of time – you could have CTR measured per hour, day, week or month. CTR is also different depending on the specific campaign you're advertising for.
      CTR campaigns are usually done by direct marketers (such as e-commerce companies), but CTR can be used in any scenario where there's a marketer trying to get more traffic into their website through online ads. There isn't just one CTR number, there are actually many CTRs. You can get measurements based on different ad groups within the same campaign.
      </p>

      <h3>Using CTR as a benchmark  </h3>
      <p>
      CTR can be used as a benchmark for tracking the progress of future campaigns. Having CTR numbers available from the start will allow marketers to make more informed decisions on where they need to improve on their campaigns. CTR is crucial because it helps determine the overall effectiveness of an online ad campaign. also helpful in determining how advertisements are performing compared with other ads for similar keywords within the same campaign.
      CTR numbers are important because CTR is highly correlated with conversion rates. and a good marketer can determine which keywords are good investments for your business, since CTR isn't consistent across all types of search results. CTR can also be used as an easy way to compare different keywords within the same campaign.
      Overall, CTR gives marketers a benchmark for tracking the progress of future campaigns. CTR helps marketers determine which keywords are good investments, where they need to improve , and is highly correlated with conversion rates.
      </p>
      `,

      comments: 19100,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailConversionRateOptimization: {
    blog: {
      img: require("@src/assets/images/banner/conversion-rate-optimazation.jpg")
        .default,
      title: "Conversion rate optimization in 9 practical steps.",
      avatar: require("@src/assets/images/portrait/small/avatar-s-7.jpg")
        .default,
      userFullName: "Ghani Pradita",
      createdTime: "Feb 03, 2022",
      tags: ["Conversion", "SEO"],
      metaDescription:
        "Learn how to optimize the convertion rate on your website with in minutes and a 9 easy staeps anyone can do >>",
      metaTitle: "Conversion rate optimization in 9 practical steps.",
      content: `

      `,

      comments: 19100,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },

  blogDetailIncreaseClickThroughRate: {
    blog: {
      img: require("@src/assets/images/banner/7.jpg").default,
      title: "How to Increase Click-Through Rate: A Comprehensive Guide",
      avatar: require("@src/assets/images/portrait/small/avatar-s-7.jpg")
        .default,
      userFullName: "Ghani Pradita",
      createdTime: "Jan 17, 2022",
      tags: ["Conversion", "SEO"],
      metaDescription:
        "CTR, click-through rate, is a metric that tells you how often people click on your links. Learn how to increase click-through rate >>",
      metaTitle: "How to Increase Click-Through Rate",
      content: `

      <p>CTR, click-through rate, is a metric that tells you how often people click on your links as opposed to just seeing them. CTR is determined by dividing the total number of clicks by the total number of impressions. CTR is one of the most important metrics for online marketers because it can be used to measure the effectiveness of a campaign or an ad. In this comprehensive guide, we will discuss all aspects of CTR and provide tips on how to increase it!</p>
      <h4>What on this article</h4>
      <ul>
        <li>The 3 T’s - the main factors that have the biggest impact on CTR</li>
        <li>How the position of the ad or link impacts CTR</li>
        <li>How the headline or title of the ad or link impacts CTR</li>
        <li>How the relevance of the ad or link to the viewer's interests impacts CTR</li>
        <li>
          How to improve conversion rate


        </li>
      </ul>
      <h2>The main factors that have the biggest impact on CTR </h2>
      <p>Now that you understand what CTR is and what affects it, you can start to focus on improving your click through rate. The main thing you need to do is make sure your ad or link is in a position where people are most likely to see it, and make sure the headline or title of the ad or link catches their attention. You can do this by testing different headlines or titles to see which one gets the most clicks. You should also make sure that your ad or link is relevant to what people are looking for, since they will be more likely to click on it if it's something they actually want.


      </p>
      <p>
      Or if we need the remember is, those factors also known as “The 3 t’s”:
      </p>
      <ul>
            <li>The position of the ad or link</li>
            <li>The headline or title of the ad or link</li>
            <li>The relevance of the ad or link to the viewer's interests</li>

          </ul>
      <h2>How the position of the ad or link impacts CTR </h2>
      <p>
      Positioning is key when it comes to CTR. Ads that are in the top positions (position one through four) have a significantly higher CTR than those that are in lower positions. The reason for this is because people generally only look at the first few results on a page, so ads that are further down the page get less exposure.

      </p>

      <h2>How the headline or title of the ad or link impacts CTR </h2>
      <p>
      The title of an ad or link also has an impact on CTR. For example, if you have a blog post about how to increase CTR and your title is "How To Increase CTR," then more people will click through than if your headline were something like "What Is CTR?"


      </p>
      <h2>How the relevance of the ad or link to the viewer's interests impacts CTR  </h2>
      <p>
      Finally, the relevance of an ad or link to the viewer's needs and interests can have a big impact on CTR. If someone is searching for information about how to increase CTR, then they are likely to click on links that are relevant to that topic.


      </p>
      <h2>How to improve conversion rate  </h2>
      <p>
      Keeping the above information in mind, here are a few tips to increase your CTR :

      </p>
      <h3> Pay attention to ad positioning </h3>
      <p>

      Placing ads on the top of the page or sidebar will give them more exposure than if they were placed below the fold. This is because most people do not scroll down beyond the first screen of search results, but rather just glance at the first page and then click an ad if they're interested.

      </p>
      <h3>Use bold headings  </h3>
      <p>
       Placing keywords in text ads as a heading or subheading can attract attention to those particular terms and encourage CTR. For example, instead of simply placing "Yoga Studio" as an ad title, place "Yoga | Studio | Yoga Studio" to emphasize the particular words.


      </p>
      <h3>Draw attention with color  </h3>
      <p>
       There are a lot of shades, tints and colors that your text ads can be displayed in, but certain key shades tend to get more clicks than others. Research has shown that reds and oranges have a high CTR , while yellows and blues have a lower CTR.


      </p>
      <h3> Use stunning graphics </h3>
      <p>
       Graphics are one of the best ways to increase your click-through rate, especially when you can incorporate them into your ads. For example, most people tend to notice images before they notice text, so use an image or symbol in your ad that will draw attention to it.


      </p>
      <h3>Strong call to action  </h3>
      <p>

      One of the best ways to improve CTR is by using strong calls to action. A call to action is a statement that instructs the reader what they should do next, such as "Click here" or "Buy now." You can use these types of statements at the end of an article or in your email signature line to encourage people who read it and are interested in learning more about CTR.
      You can also include calls to action in the beginning of an email or blog post by using a header like "Read more" or "Learn how."

      </p>
      <h3>Use images and videos  </h3>
      <p>

      Another great way to increase CTR is by using images and videos. People are more likely to click on links that include visuals, so if you can find ways to incorporate them into your content, then you will see a boost in CTR.

      </p>
      <h3>The golden rule - Type a content  </h3>
      <p>

      Last but not least, make sure that your content is high-quality and relevant to your target audience. If you produce valuable content that is interesting and engaging, then people will be more likely to click through on your links, google will give you a better rank and over all your metrix will improve. its not a secret that content is the king!
      By following these tips, you should see an increase in CTR over time!
      Oh, one last thing - we have talked about what CTR is and how we can improve it. but none of these matters if we don't know to to measure is so you can learn how to measure CTR in our article “how the measure metrics - CTR”

      </p>
      <p>
       Thanks for reading!


      </p>
      `,

      comments: 19100,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailConversionRateOptimizationPracticalSteps: {
    blog: {
      img: require("@src/assets/images/banner/conversion-rate-optimazation.jpg")
        .default,
      title: "Conversion rate optimization in 7 practical steps",
      avatar: require("@src/assets/images/portrait/small/avatar-s-11.jpg")
        .default,
      userFullName: "Helena Hunt",
      createdTime: "Apr 14, 2022",
      tags: ["Conversion", "SEO"],
      metaDescription:
        "In this article, we will discuss 7 (and another bonus tip at the end!) steps that will help increase conversion rate. >>",
      metaTitle: "Conversion rate optimization in 7 practical steps",
      content: `

      <p>Many businesses spend time trying to increase traffic to their website, and fail to improve the conversion rate once they get there. This is a huge mistake. In this article, we will discuss 7 (and another bonus tip at the end!) steps that will help increase and optimize your conversion rates and generate more revenue in your eCommerce!</p>
      <p>
      When talking about conversion rate we are referring to the number of transactions from the total users in the website. the bigger the number of transactions, the better the conversion rate - so we aspire to increase the amount of transactions. how to do it? Let's find out.
      </p>
      <p>
      Oh and by the way, we have a special tip waiting for you at the end of the article.
      </p>

      <h2>Optimize the conversion rate by attract users using a “hook” </h2>

      <p>
      Have a strong offer. You need to have an offer that is irresistible to your customers. In digital marketing, we call it a “HOOK” offer. Try to think about what can be your Hook to get your customer's attention and get high engagement. For e.g; this could be a discount, free shipping, or something else that is valuable to them. More engagement probably will lead to more conversions.
      </p>
      <p>
      Here we can see a “hook” been used in a meta title on google search result:
      </p>

      <p>
      The meta title contains two “hooks” in it:
      </p>


      <h3>price and a special symbol</h3>
      <p>
      Using a price as a “hook” is one of the most efficient ways to increase CTR and have people's attention. Either you use it on meta titles or inside your website - a clear indication of price is one of the most effective “hooks” you can use.
      </p>
<h3>Special description</h3>
<p>
By using a special description on the service\website in the mate title will make users more likely to engage and click. Here we can see that a statement of “Quick online order” is used to make users prefer this result on top of others.
      </p>


      <h2>Optimize the conversion rate by better UX </h2>
      <p>
      Make sure your website is user-friendly. Your customers should be able to easily find what they are looking for on your website. If they can’t find it, they will leave and not come back. Another point to consider is that giving users a good experience will lead to them trusting your website and more likely to buy from you.
Here are 4 basic steps you can use to make a better ux and optimize your conversion rate:

      </p>

      <ul>
        <li>Navigation should remain consistent throughout a page, and within the site.</li>
        <li>Keep fonts large enough for everyone to read and consistent in type throughout the entire website.</li>
        <li>Include social media buttons so customers can easily follow your brand online, read reviews and see the activity taken by the brand on social platforms.</li>
 <li>Use logos and images on the same page together with text, instead of in separate boxes with links below. Being able to see it all without scrolling is more user-friendly.</li>
      </ul>
<p>
All in all, UX can be hard for a non professional but due to the fact that UX design is becoming a major player in the field of the digital age, you can find a lot of guids and tips. Here are some of our Favorite sites for UX\UI design:
      </p>

      <p>
      <span class="c10"><a class="c6" href="https://www.google.com/url?q=https://uxplanet.org&amp;sa=D&amp;source=editors&amp;ust=1649947587052628&amp;usg=AOvVaw0du0HdXnRWxfRTG1wLs-Bk">www.uxplanet.org</a></span>


      </p>
      <p class="c4"><span class="c10"><a class="c6" href="https://www.google.com/url?q=http://www.toptal.com/designers/blog&amp;sa=D&amp;source=editors&amp;ust=1649947587053274&amp;usg=AOvVaw0hWv1U0tdt1WS5grdCDfSL">www.toptal.com/designers/blog</a></span><span class="c0">&nbsp;- The blog of Toptal is full with different guides and articles written by professionals.</span></p>




      <h2>Optimize the conversion rate by better UI</h2>
      <p>
We know what you guys think, basic UI should cut it right? no! Studies and researches have shown a direct link between great visual content and conversion rate. So in your eCommerce you should include images of your products as well as graphics that explain what you are selling and stunning graphics to give you users a good feeling. Having great visuals will help engage your customers and keep them on your website longer - and there for optimizing your conversion rate.
      </p>
      <p>

      But some of you might ask “what if i can't afford a graphic designer?”. The good news is we have a solution for you! if you haven't tried elements.envato.com and canve.com its time to give it a go! with these two together you'll get access to a huge source of graphic templates, photos, videos and much more! combined with the power of canva's easy and intuitive design platform you'll be able to create stunning designs without using a professional.
      </p>


      <h2>Optimize the conversion rate with CTA </h2>
      <p>
      CTA (or call to action) is one of the most important aspects in optimizing conversion rate, the CTA unconsciously guides users into doing what you want - making them buy. This is the part of your website where you ask your customers to take action, such as buying a product or signing up for your email list. You need to make sure your call to action is clear and easy to understand, another common mistake is to place the CTA under the folder instead of above the folder so keep that in mind.

      </p>
      <p>
      CTAs can be used for 5 different types of goals you like to achieve from the users:
      </p>

      <ul>
        <li><h4>
        CTA for discovery - Subscribe / Sign up now
        </h4></li>
<li><h4>
CTA for consideration - Add to cart / Add to wishlist
        </h4></li>
<li><h4>
CTA for revenue - Buy now / Checkout
        </h4></li>
<li><h4>
CTA for retention - Coupon code / VIP Perks
        </h4></li>
  <li><h4>
  CTA for advocacy - Review / Ambassdor
        </h4></li>
              </ul>

              <p class="c4"><span class="c1">A great resource for CTA understanding is </span><span class="c10"><a class="c6" href="https://www.google.com/url?q=https://www.bigcommerce.co.uk/blog/ecommerce-ctas/%233-the-buy-button-add-to-cart-button&amp;sa=D&amp;source=editors&amp;ust=1649947587057371&amp;usg=AOvVaw1oYXne20ULtWMxPjtWmRdK">this article</a></span><span class="c0">.</span></p>


      <h2>
      Optimize the conversion rate with social media
      </h2>
      <p>

      More than 50% of Facebook's revenue comes from mobile ads, Twitter posts generate over 200 million clicks per day , and LinkedIn gets more traffic than Google+. With all this data, it doesn't take a genius to see that social media can be an important part of your business strategy.
Do you know what I typically see with many businesses' Facebook pages? They always post links to their products on their pages, which is fine if you are using Facebook primarily to market your products. However, most businesses I have worked with are not really looking to sell their products on social media pages. So the main problem is that most people dont really use the power of social media and if they do, it's just not efficient as it should be. The problem is that they focus on pushing sales instead of promoting engagement and sharing relevant content.
We can go on and on about social media but for now, just keep in mind that you want people to trust your website and buy from you, keep that in mind while you are building your social media presence.

      </p>


      <h2>
      Optimize the conversion rate with targeting the right traffic
      </h2>
      <p>
      Make sure you are getting quality traffic to your website. This means that a lot of the people who visit your site will end up buying something or taking action on another page, such as signing up for an email list. If not, it’s just wasting money and time!
In case you're not sure who to target, just think about your product and who are the main users of it. let say you have a Boat company, there is no need to target people who are not living near water sources cause its most likely they will never buy a boat.

      </p>
      <p>
      This step is important to get right so your time and money won't be wasted, if you're not sure how to do a market and user research on your product you should consider getting help from a professional.

      </p>

      <h2>
      Optimize the conversion rate with good customer service
      </h2>
      <p>
      Have great customer service and make sure your customers are happy. If they aren’t, then you will lose them as a customer forever! You need to take actions that show they care about their happiness such as responding quickly or sending free product samples. This will increase the likelihood of repeat business and word-of-mouth marketing.
      </p>
      <p>
      And it's about making sure your emails are highly targeted and relevant for the interests of your customers. This will increase open rates, click through rate, as well as conversions! And please make sure to have a strong call-to-action in your email messages. You need to have an offer that you want your customers to take action on, such as buying a product or signing up for your email list.
      </p>


      <h3> The Golden Tip </h3>
      <p>
      After all of that being said we have a surprise for you - the 8th tip that will not only optimize your conversion rate but also will make you unique and make your eCommerce store pop-out.
      </p>

     <h2>
      Optimize the conversion rate with good customer service
      </h2>
      <p>
      As you may have understood by now, we are trying to get users to love our site, build a trust between us and make them have a good experience while scrolling through the store. to get all of this done we have made an easy platform for building a unique (and free for 21 days!) game for your store.
      </p>

      <h2>So how Wiply can help you </h2>
      <p>
      Here in wiply we improve your eCommerce conversion rate with fun games that you can easily embed in your website no matter which platform you are using.
      </p>
      <h3>How does gamification with Wiply work? </h3>
      <p>

      With the Wiply platform you'll be able to create a unique game, with your own brand in it for free! The games are easily embedded to any platform and with basic knowledge. With the games you'll be creating a more engaging environment for your users and have them win a prize.
By making users play and win a prize such as discount, free shipping, special offers and so on, we are making them feel like they have won something special and more likely they will buy from you.


      </p>
      <p>
      We also know that measuring data is important for growth, so all of our games can be tracked and monitored including how many transactions are made with the special prize won in the game.


      </p>
 <p>
 Our pricing plans are monthly so you can decide as you go and grow if you want more or less games, users playing or design support. But hold on, don't pay yet! you can enjoy a special offer for 21 days for free! yes, free. just have a look at our gemifying pricing plans.


      </p>
 <p>
 Hopefully you'll find this article helpful, stay tuned for other guides, articles and surprises from us :)


      </p>

      `,

      comments: 19100,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailGamificationStrategyTips: {
    blog: {
      img: require("@src/assets/images/banner/strategy-tip-for-your-business.jpg")
        .default,
      title: "Gamification Strategy Tips for Your Business",
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "Max Hunt",
      createdTime: "Jun 12, 2022",
      tags: ["E-commerce", "Gaming"],
      metaDescription:
        " Companies are increasingly looking to gamification as a way to engage and motivate employees, customers, and partners. learn how to build a gamification strategy like a pro >>",
      metaTitle: "Gamification Strategy Tips for Your Business ",
      content: `

      <p>Companies are increasingly looking to gamification as a way to engage and motivate employees, customers, and partners. By definition, gamification is the use of game mechanics and game-thinking in non-game contexts to engage users and solve problems.</p>
      <p>
      Gamification can be used in a variety of business applications, from sales and marketing to employee training and development. When used correctly, gamification can drive positive business outcomes, such as increased sales, higher customer engagement, and improved employee performance.
      </p>
      <p>
      For businesses, it is important to have a clear understanding of what they want to achieve with gamification before implementing any strategies. Without a goal in mind, businesses may find themselves wasting time and resources on gamification initiatives that don't produce the desired results.
      </p>
      <p>
      When done right, gamification can be a powerful tool for businesses to engage and motivate employees, customers, and partners. By taking the time to develop a clear gamification strategy, businesses can ensure that they are using gamification in a way that will drive positive business outcomes.
      </p>
      <p>
      Here are the main Strategy Tips when building a gamification business strategy:
      </p>

      <h2>1. Define your goals and objectives </h2>

      <p>
      Before you can start implementing a gamification strategy, you need to know what you want to achieve. Do you want to increase employee productivity? Drive more sales? Improve customer satisfaction?
            </p>

      <h2>2. Identify your target audience</h2>
      <p>
      Who do you want to reach with your gamification strategy? Employees? Customers? Both? Once you know your target audience, you can start to think about what sorts of games and rewards will appeal to them.
            </p>
<h2>3. Choose the right platform</h2>
<p>
There are a variety of gamification platforms available, each with its own strengths and weaknesses. Do some research to find the one that best meets your needs.
      </p>



      <h2>4. Implement the strategy </h2>
      <p>
      Once you have your goals, target audience, and platform is chosen, it's time to start implementing your gamification strategy. This will involve creating games and rewards, as well as setting up tracking and reporting mechanisms to measure success.

      </p>
      <h2>5. Evaluate and adjust  </h2>
      <p>
      Periodically check in on your gamification strategy to see how it's performing. Are your goals being met? If not, make some adjustments to your games and rewards to try and improve results.

      </p>


      `,

      comments: 24,
      bookmarked: 9,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailGamificationToImproveUX: {
    blog: {
      img: require("@src/assets/images/banner/4.jpg").default,
      title: "How to use gamification to improve UX",
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      createdTime: "Jun 15, 2022",
      tags: ["Gaming", "Sales"],
      metaDescription:
        "Gamification can be a great way to improve UX, by adding games and making the experience more fun and engaging. learn how >>",
      metaTitle: "How to use gamification to improve UX ",
      content: `
      <p>
      User experience is important for any website or application - it is what makes the difference between a user coming back or leaving. Gamification can be a great way to improve UX, by adding games and making the experience more fun and engaging. There are many benefits to doing this, including increased user interaction, loyalty, and retention.
      </p>
       <p>
       One of the main reasons why good UX is so important is because it can make or break a website or application. If a user has a bad experience, they are likely to leave and never come back. On the other hand, if they have a good experience, they are more likely to return and recommend the site or app to others. This is why it is so important to focus on UX when creating a website or app.

      </p>
     <p>
     There are many benefits to using gamification to improve UX. Gamification can make the experience more fun and engaging, which will lead to increased user interaction. Additionally, it can also increase loyalty and retention. By adding games to a website or app, users are more likely to come back and continue using it.

      </p>
     <p>
     Wiply is a great platform that allows you to easily add games and gamify your website or application. With full customization control, you can create games that fit perfectly into your existing UX. So why not give it a try and see how it can improve your user experience?
      </p>
      `,

      comments: 10,
      bookmarked: 139,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailGuideUsingWiply: {
    blog: {
      img: require("@src/assets/images/banner/5.jpg").default,
      title: "How to Get Leads for Your Business: A Guide Using Wiply.com",
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      createdTime: "Aug 28, 2022",
      tags: ["Conversion", "Sales"],
      metaDescription:
        "From offering rewards to creating a sense of urgency, learn three gamification tactics that have been proven to work in increasing conversion rates for all types of businesses. Read more >>",
      metaTitle:
        " Gamification Tactics That Work to Create Conversion Rates of at Least Five Percent",
      content: `
      <p>
      Conversion rates can be a tricky thing to figure out. What works for one business might not work for another, and what works today might not work tomorrow. However, there are some general tactics that tend to help most businesses achieve a conversion rate of 5%-8%. In this article, we will discuss three of those tactics - Gamification is the use of game mechanics in non-gaming contexts, and it can be a very effective way to encourage people to take action.

           </p>
       <p>
       Wiply.com is a powerful lead generation tool that can help you get more leads for your business. With Wiply.com, you can target your ideal customer and reach out to them directly. You can also create custom forms to capture leads from your website or blog. Plus, our platform integrates with many popular CRM and marketing automation tools, so you can easily follow up with your leads.

      </p>
     <p>
     To get started, simply create a free account and sign up for our 14-day trial. Then, you can start creating your forms and customizing your lead capture process. Once you're ready to start reaching out to leads, you can purchase credits that will allow you to contact your leads directly.

      </p>
     <p>
     So what are you waiting for? Sign up for Wiply.com today and start generating more leads for your business! You won't be disappointed.

     </p>
      `,

      comments: 15,
      bookmarked: 13,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailImproveConversionRate: {
    blog: {
      img: require("@src/assets/images/banner/4.jpg").default,
      title: "How Gamification Can Improve the Conversion Rate on Your Website",
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      createdTime: "Jul 03, 2022",
      tags: ["Conversion", "Sales"],
      metaDescription:
        "How Gamification Can Improve the Conversion Rate on Your Website",
      metaTitle:
        "How Gamification Can Improve the Conversion Rate on Your Website ",
      content: `
      <p>
      There are many ways to improve the conversion rate on a website. Some business owners focus on improving the design of their website, while others focus on generating more traffic. However, one of the most effective ways to improve conversions is through gamification. In this article, we will discuss how gamification can help improve your conversion rate.
Gamification is the process of applying game-thinking and mechanics to non-gaming activities in order to engage users and motivate them to achieve their goals. When it comes to conversion rate optimization, gamification can be a powerful tool for increasing engagement and boosting conversions. In this blog post, we will discuss how gamification can help improve your website's conversion rate. We'll also look at some examples of businesses that are using gamification successfully to increase their conversions

           </p>
           <h3>
           Gamification can help improve your website's conversion rate in several ways
           </h3>
       <p>
      First, it can increase user engagement by providing more fun and interactive experience. Second, it can motivate users to take action by offering rewards for completing desired actions. Finally, it can create a sense of social competition and community involvement that encourages users to keep coming back
      </p>
     <p>
   When used correctly, gamification can be a powerful tool for increasing engagement and boosting conversions. If you're looking to improve your website's conversion rate, consider implementing some of these strategies. You might be surprised at how much of a difference they can make
      </p>
     <p>
    There are many different ways to gamify a website. One common approach is to use points, badges, and leaderboards. Points can be awarded for taking certain actions on a website, such as signing up for a newsletter or making a purchase. Badges can be awarded for completing tasks or reaching milestones. Leaderboards can be used to encourage competition and motivate users to take more action
     </p>
     <p>
    Another way to gamify a website is to use rewards. Rewards can be given for taking certain actions, such as signing up for a newsletter or making a purchase. Rewards can also be given for reaching milestones, such as completing a task or level
     </p>
      <p>
    Gamification can be used in many different ways to improve the conversion rate on a website. By incorporating game-like elements, businesses can engage visitors and encourage them to take action. If you’re looking for ways to improve your conversion rate, gamification is definitely worth considering
     </p>
      `,

      comments: 15,
      bookmarked: 13,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailFutureOfAdvertising: {
    blog: {
      img: require("@src/assets/images/banner/5.jpg").default,
      title:
        "Why Wiply.com is the Future of Advertising: Add Innovation for Maximum Results",
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      createdTime: "Jul 07, 2022",
      tags: ["Conversion", "Sales"],
      metaDescription:
        "Why Wiply.com is the Future of Advertising: Add Innovation for Maximum Results",
      metaTitle: "Add Innovation for Maximum Results ",
      content: `
      <p>
      Have you ever been to a website and been inundated with ads? It can be really frustrating, especially when they are irrelevant to what you are looking for. Well, Wiply.com is the future of advertising, and it will change the way you see online ads forever! With Wiply.com, you can add innovation to your advertising for maximum results
      </p>

       <p>
       So, what is Wiply.com? Wiply.com is an online platform that helps businesses connect with customers in a more innovative way. With Wiply.com, businesses can create ads that are interactive and engaging, rather than the traditional static ads. This means that customers are more likely to pay attention to the ad, and they are also more likely to remember the product or service
      </p>
     <p>
     In addition, Wiply.com offers a range of features that make it easy for businesses to target their ads. For example, you can target ads by location, demographics, interests, and even behaviors. This ensures that your ad reaches the right people, and it also makes it more likely that they will take action
      </p>
     <p>
So, if you are looking for a more innovative way to advertise, then look no further than Wiply.com! With Wiply.com, you can add innovation to your advertising for maximum results. Try it today and see the difference!
     </p>
<p>
When it comes to advertising, the goal is to reach your target audience in the most effective way possible. Wiply.com does just that by offering interactive and engaging ads that are easy to remember. In addition, businesses can target their ads using a variety of criteria such as location, demographics, interests, and behaviors. This ensures that your ad reaches the right people and is more likely to result in conversions. If you are looking for a more innovative way to advertise, then look no further than Wiply.com! Try it today and see the difference!
     </p>

      <h3>
          Why Wiply.com is the Future of Advertising: Add Innovation for Maximum Results
           </h3>
<p>
If you're anything like me, you're sick and tired of seeing the same boring ads online. You know, the ones that are just a bunch of text or a single image? Yeah, those are pretty much the worst.

     </p>
<p>
Thankfully, there's a new kid on the block that's shaking things up. Wiply.com is an online platform that helps businesses connect with customers in a more innovative way. With Wiply.com, businesses can create ads that are interactive and engaging, rather than the traditional static ads. This means that customers are more likely to pay attention to the ad, and they are also more likely to remember the product or service.

     </p>
<p>

     </p>
      `,

      comments: 15,
      bookmarked: 13,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailGamificationTactics: {
    blog: {
      img: require("@src/assets/images/banner/2.jpg").default,
      title: "5 Ways to Create Conversion Rates of 5%-8%: Gamification Tactics",
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      createdTime: "Jul 03, 2022",
      tags: ["Conversion", "Sales"],
      metaDescription:
        "How Gamification Can Improve the Conversion Rate on Your Website",
      metaTitle:
        "How Gamification Can Improve the Conversion Rate on Your Website ",
      content: `
      <p>
      There are many ways to improve the conversion rate on a website. Some business owners focus on improving the design of their website, while others focus on generating more traffic. However, one of the most effective ways to improve conversions is through gamification. In this article, we will discuss how gamification can help improve your conversion rate.
Gamification is the process of applying game-thinking and mechanics to non-gaming activities in order to engage users and motivate them to achieve their goals. When it comes to conversion rate optimization, gamification can be a powerful tool for increasing engagement and boosting conversions. In this blog post, we will discuss how gamification can help improve your website's conversion rate. We'll also look at some examples of businesses that are using gamification successfully to increase their conversions

           </p>
<ol>
        <li>
         <p>
         One way to use gamification to increase conversion rates is to offer rewards for taking the desired action. For example, you could offer a discount on a purchase, or entry into a contest, for people who sign up for your email list. Another tactic is to create a sense of urgency by offering limited-time discounts or bonuses. This encourages people to act now rather than wait, which can increase conversion rates.
         </p>
        </li>
<li>
         <p>
         You can also use gamification to create a sense of social proof. This is when people see others taking the desired action and feel more likely to do it themselves. For example, you could show how many people have already signed up for your email list, or how many people have liked your Facebook page.

         </p>
        </li>
<li>
         <p>
         Finally, you can use gamification to make the desired action feel more fun and engaging. For example, you could add a game element to your website or make the sign-up process for your email list more interactive. By making the experience more enjoyable, people are more likely to take the desired action.


         </p>
        </li>


              </ol>



       <p>
     All of these tactics can help you increase your conversion rates. However, it's important to experiment and find what works best for your business. There is no one-size-fits-all solution when it comes to conversion rates. But if you use these gamification tactics, you'll be well on your way to achieving the desired results.


       </p>

      `,

      comments: 15,
      bookmarked: 13,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  blogDetailWebsiteBudget: {
    blog: {
      img: require("@src/assets/images/banner/7.jpg").default,
      title: "How to Gamify Your E-commerce Website on a Budget",
      avatar: require("@src/assets/images/portrait/small/avatar-s-5.jpg")
        .default,
      userFullName: "John Mory",
      createdTime: "Aug 15, 2022",
      tags: ["Conversion", "Sales"],
      metaDescription:
        " Wiply.com is a great solution for businesses looking to add gamification to their website on a budget. Read more >> ",
      metaTitle: "How to Gamify Your E-commerce Website on a Budget",
      content: `
      <p>
      When it comes to e-commerce, there is no question that businesses need to find ways to stand out from the competition. One way to do this is by gamifying your website. This can be a costly endeavor, however – or is it? Wiply.com offers e-commerce gamification for only $49 per month, and we believe it is one of the best values ​​on the market. In this article, we will discuss the benefits of e-commerce gamification and how wiply.com can help you get started without breaking the bank.
           </p>

       <p>
    Gamification is the process of adding Gamify elements to your website in order to engage and motivate users. Common features include points, badges, leaderboards, and rewards. When done correctly, gamification can increase user engagement, loyalty, and conversions. It can also help you collect valuable data about your users' behavior.

       </p>
         <p>

There are many benefits to Gamify your e-commerce website. First, it can help you stand out from the competition. With so many businesses selling similar products, anything you can do to make your site more unique is a valuable asset. Gamification can also increase loyalty and repeat customers. Users who have invested time and effort into earning points and badges are more likely to come back to your site in the future. Finally, gamification can increase conversions by motivating users to take action.

       </p>
         <p>
    Wiply.com is a great solution for businesses looking to add gamification to their website on a budget. For only $49 per month, you get access to all the features you need to get started, including points, badges, leaderboards, and rewards. You also get access to our team of experts who can help you customize your gamification strategy to fit your unique needs.

       </p>
         <p>
    If you're ready to take your e-commerce business to the next level, contact wiply.com today and let us show you how gamification can help you achieve your goals.

       </p>
       `,

      comments: 15,
      bookmarked: 13,
    },
    comments: [
      {
        avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
          .default,
        userFullName: "Chad Alexander",
        commentedAt: "May 24, 2022",
        commentText:
          "A variation on the question technique above, the multiple-choice question great way to engage your reader.",
      },
    ],
  },
  // edit
  blogEdit: {
    avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg").default,
    userFullName: "Chad Alexander",
    createdTime: "May 24, 2022",
    blogTitle: "The Best Features Coming to iOS and Web design",
    blogCategories: [
      { value: "fashion", label: "Fashion" },
      { value: "gaming", label: "Gaming" },
    ],
    slug: "the-best-features-coming-to-ios-and-web-design",
    status: "Published",
    excerpt:
      "<p>Cupcake ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Carrot cake macaroon chocolate cake. Jelly brownie jelly. Marzipan pie sweet roll.</p><p><br></p><p>Liquorice dragée cake chupa chups pie cotton candy jujubes bear claw sesame snaps. Fruitcake chupa chups chocolate bonbon lemon drops croissant caramels lemon drops. Candy jelly cake marshmallow jelly beans dragée macaroon. Gummies sugar plum fruitcake. Candy canes candy cupcake caramels cotton candy jujubes fruitcake.</p>",
    featuredImage: require("@src/assets/images/slider/03.jpg").default,
  },
};

mock.onGet("/blog/list/data").reply(() => [200, data.blogList]);
mock.onGet("/blog/list/data/sidebar").reply(() => [200, data.blogSidebar]);
// mock.onGet('/blog/list/data/detail').reply(() => [200, data.blogDetail])
mock.onGet("/blog/list/data/edit").reply(() => [200, data.blogEdit]);
mock
  .onGet("/blog/list/data/detail/1")
  .reply(() => [200, data.blogDetailIncreasingUserEngagement]);
mock
  .onGet("/blog/list/data/detail/2")
  .reply(() => [200, data.blogDetailMobileCommerce]);
mock
  .onGet("/blog/list/data/detail/3")
  .reply(() => [200, data.blogDetailBenefitsOfGamifying]);
mock
  .onGet("/blog/list/data/detail/4")
  .reply(() => [200, data.blogDetailOptimizeWebsiteContent]);
mock.onGet("/blog/list/data/detail/5").reply(() => [200, data.blogDetailCTR]);
mock
  .onGet("/blog/list/data/detail/6")
  .reply(() => [200, data.blogDetailIncreaseClickThroughRate]);
mock
  .onGet("/blog/list/data/detail/7")
  .reply(() => [200, data.blogDetailConversionRateOptimization]);
mock
  .onGet("/blog/list/data/detail/8")
  .reply(() => [200, data.blogDetailConversionRateOptimizationPracticalSteps]);
mock
  .onGet("/blog/list/data/detail/9")
  .reply(() => [200, data.blogDetailGamificationStrategyTips]);
mock
  .onGet("/blog/list/data/detail/10")
  .reply(() => [200, data.blogDetailGamificationToImproveUX]);
mock
  .onGet("/blog/list/data/detail/11")
  .reply(() => [200, data.blogDetailGuideUsingWiply]);
mock
  .onGet("/blog/list/data/detail/12")
  .reply(() => [200, data.blogDetailImproveConversionRate]);
mock
  .onGet("/blog/list/data/detail/13")
  .reply(() => [200, data.blogDetailFutureOfAdvertising]);
mock
  .onGet("/blog/list/data/detail/14")
  .reply(() => [200, data.blogDetailWebsiteBudget]);
mock
  .onGet("/blog/list/data/detail/15")
  .reply(() => [200, data.blogDetailGamificationTactics]);
