import ClientOAuth2 from "client-oauth2";
import axios from "axios";
import { setAuthHeader } from "../utils/axiosHeaders";
//import {
//   storeUserError,
//   storeUser,
//   userSignedOut,
// } from "../../../actions/authActions";
// import store from "store";
import { store } from '../redux/store'

const config = {
  clientId: "Wiplify_React",
  clientSecret: "ehbn8765",
  accessTokenUri: `https://admin.wiply.com/connect/token`,
  userInfoUri: `https://admin.wiply.com/api/identity/my-profile`,
  endsessionUri: `${process.env.REACT_APP_AUTH_SERVER_URL}/connect/endsession`,
  introspectURL: `${process.env.REACT_APP_AUTH_SERVER_URL}/connect/introspect`,
  scopes: ["openid", "profile", "email", "role"],
};

const oauth = new ClientOAuth2(config);

export const userProfile = () => {
  const token = localStorage.getItem("access_token");
  if (token) {
     axios.defaults.headers.common["Authorization"] = token ? `Bearer ${  token}` : "";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    return axios.get(config.userInfoUri);
  } else {
    return null;
  }
};
export async function loadUserFromStorage(store) {
  try {
    const user = await userProfile();
    if (!user) {
      // return store.dispatch(storeUserError());
    }
    // localStorage.setItem("access_token", result.accessToken);
    // store.dispatch(storeUser(user.data));
  } catch (e) {
    console.error(`User not found: ${e}`);
    // store.dispatch(storeUserError());
  }
}


export const login = (params) => {
  return oauth.owner
    .getToken(params.username, params.password)
    .then((result) => {
      localStorage.setItem("access_token", result.accessToken);
      setAuthHeader(result.accessToken);
      // const user = await userProfile();
      //get and store user details
       loadUserFromStorage(store);
      // try {
      //       const user = await userProfile();
      //       if (!user) {
      //         return store.dispatch(storeUserError());
      //       }
      //       store.dispatch(storeUser(user.data));
      //     } catch (e) {
      //       console.error(`User not found: ${e}`);
      //       store.dispatch(storeUserError());
      //     }
      return result;
    })
    .catch((error) => {
      localStorage.removeItem("access_token");
      console.log(error);
    });
};

// export const logoff = () => {
//   userSignedOut();
// };


export const validateToken = (token) => {
  return axios
    .post(config.introspectURL, { token })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.error(error);
    });
};

export default oauth;
