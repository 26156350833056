// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/store'

// ** Intl & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from "./utility/context/ThemeColors";

import oauth, { loadUserFromStorage } from "./services/oauth";
import AuthProvider from "./utils/authProvider";

import "./configs/i18n";
// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

import "./@fake-db";
// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

import TagManager from "react-gtm-module";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
// import './styles/globals.css'
// ** Service Worker
import * as serviceWorker from "./serviceWorker";

const tagManagerArgs = {
  gtmId: "GTM-5LMXJG6V",
};

// const options = {
//   // licenseKey: 'gmrchk',
//   focusableElementsOffsetX: 5,
//   focusableElementsOffsetY: 5,
//   //color: '#666666',
//   color: "linear-gradient( 90deg, rgba(88,8,251,1) 0%, rgba(153,41,234,1) 100% )",
//   dotColor: "#620DF9",
//   invert: false,
//   focusableElements:
//     "[data-blobity], a:not([data-no-blobity]), button:not([data-no-blobity]), [data-blobity-tooltip]",
//   font: "'Montserrat','Source Sans Pro',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif",
//   fontSize: 14,
//   fontWeight: 400,
//   opacity: 0.8,
//   // zIndex: 10, mnhkjhk
//   fontColor: "#ffffff",
//   zIndex: -1,
//   size: 40,
//   radius: 4,
// };

TagManager.initialize(tagManagerArgs);
// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
// new Blobity(options)
// const blobityInstance = Blobity(options);
// const blobity = useBlobity();
ReactDOM.render(
  <Provider store={store}>
    <AuthProvider userManager={oauth} store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <LazyApp />
            <ToastContainer newestOnTop />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
