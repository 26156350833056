// ** Auth Endpoints
export default {
  // loginEndpoint: '/jwt/login',
  loginEndpoint: 'https://admin.wiply.com/connect/token',
  registerEndpoint: 'https://admin.wiply.com/api/account/register',
  refreshEndpoint: '/jwt/refresh-token',
  // ** This will be prefixed in authorization header with token
  logoutEndpoint: 'https://admin.wiply.com/api/account/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
